function FullBlog(props) {
  // const pathName = window.location.pathname;
  // console.log(pathName);
  props.func("My name is Dean Winchester & this is my brother Sammie");
  return (
    <section id="blog-single" class="blog">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-8 entries">
            <article class="entry entry-single">
              {/* <div class="entry-img">
                <img
                  src="https://miro.medium.com/max/875/0*1NA1lnVrlv85c128.png"
                  alt=""
                  class="img-fluid"
                />
              </div> */}

              <h2 class="entry-title">
                <a href="blog-single.html">Scholarships & Awards @ NIT J</a>
              </h2>

              <div class="entry-meta">
                <ul>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-person"></i>{" "}
                    <a href="blog-single.html">John Doe</a>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-clock"></i>{" "}
                    <a href="blog-single.html">
                      <time datetime="2020-01-01">Jan 1, 2020</time>
                    </a>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-chat-dots"></i>{" "}
                    <a href="blog-single.html">12 Comments</a>
                  </li>
                </ul>
              </div>

              <div class="entry-content">
                <p>
                  In the twenty-first century, affording education has become a
                  very challenging undertaking. Scholarships, Awards can finance
                  a portion or all of a student’s tuition and fees, ensuring
                  that hardworking and underserved students have an opportunity
                  to pursue higher education.
                </p>

                <blockquote>
                  <p>
                    Scholarships, however, are about more than just money. They
                    provide assistance in a variety of areas, freedom of choice,
                    and time to focus on studies, as well as other benefits that
                    will last a lifetime.
                  </p>
                </blockquote>
                <p>
                  When it comes to seeking a job after college, including
                  scholarships and awards on your resume and CV is a terrific
                  approach to increase your credibility and gain respect.
                  Obtaining a scholarship or fellowship demonstrates your
                  ability to work hard and think critically. Scholarship
                  recipients are more likely to be chosen for interviews and
                  placements by employers.
                </p>
                <p>
                  In the following article, we try to summarize all the
                  necessary information you need to avail such benefits.
                </p>

                {/* <h3>Et quae iure vel ut odit alias.</h3>
                <p>
                  Officiis animi maxime nulla quo et harum eum quis a. Sit hic
                  in qui quos fugit ut rerum atque. Optio provident dolores
                  atque voluptatem rem excepturi molestiae qui. Voluptatem
                  laborum omnis ullam quibusdam perspiciatis nulla nostrum.
                  Voluptatum est libero eum nesciunt aliquid qui. Quia et
                  suscipit non sequi. Maxime sed odit. Beatae nesciunt nesciunt
                  accusamus quia aut ratione aspernatur dolor. Sint harum
                  eveniet dicta exercitationem minima. Exercitationem omnis
                  asperiores natus aperiam dolor consequatur id ex sed.
                  Quibusdam rerum dolores sint consequatur quidem ea. Beatae
                  minima sunt libero soluta sapiente in rem assumenda. Et qui
                  odit voluptatem. Cum quibusdam voluptatem voluptatem accusamus
                  mollitia aut atque aut.
                </p> */}
                <img
                  src="https://miro.medium.com/max/875/0*1NA1lnVrlv85c128.png"
                  class="img-fluid"
                  alt=""
                />

                <h3>Scholarships</h3>
                <p>
                  Given below is the list of NIT Jalandhar’s scholarship
                  schemes👇
                </p>
                <p>1. Post-Matric Scholarship</p>
                <p>2. Samsung Star Scholarship</p>
                <p>3. SARC Scholarship</p>
                <p>4. Merit Cum Means Based Scholarship</p>
                <p>5. Shri Dorilal Agarwal National Meritorious Scholarship</p>
                <p>6. Scholarship for the BC/MBC/DNC students</p>
                <p>
                  7. Central Sector Scholarship Scheme of Top Class Education
                  For SC Students
                </p>
                <p>8. FAEA Scholarship</p>
                <p>9. Working Internships in Science and Engineering (WISE)</p>
                <p>10. National E-scholarship portal</p>
              </div>

              <div class="entry-footer">
                <i class="bi bi-folder"></i>
                <ul class="cats">
                  <li>
                    <a href="#">Business</a>
                  </li>
                </ul>

                <i class="bi bi-tags"></i>
                <ul class="tags">
                  <li>
                    <a href="#">Creative</a>
                  </li>
                  <li>
                    <a href="#">Tips</a>
                  </li>
                  <li>
                    <a href="#">Marketing</a>
                  </li>
                </ul>
              </div>
            </article>

            <div class="blog-author d-flex align-items-center">
              <img
                src="assets/img/blog/blog-author.jpg"
                class="rounded-circle float-left"
                alt=""
              />
              <div>
                <h4>Jane Smith</h4>
                <div class="social-links">
                  <a href="https://twitters.com/#">
                    <i class="bi bi-twitter"></i>
                  </a>
                  <a href="https://facebook.com/#">
                    <i class="bi bi-facebook"></i>
                  </a>
                  <a href="https://instagram.com/#">
                    <i class="biu bi-instagram"></i>
                  </a>
                </div>
                <p>
                  Itaque quidem optio quia voluptatibus dolorem dolor. Modi eum
                  sed possimus accusantium. Quas repellat voluptatem officia
                  numquam sint aspernatur voluptas. Esse et accusantium ut unde
                  voluptas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default FullBlog;
