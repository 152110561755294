import BlogTemplate from "./blogSection/singleBlog";
import FullBlog from "./blogSection/fullBlog";
import { Route, Routes, Link } from "react-router-dom";
import { Switch } from "@mui/material";
import React, { useState } from "react";

function Blogs() {
  // const pathName = window.location.pathname;
  // const [showFullBc, setFullBc] = useState(false);
  // console.log(pathName);
  // const pull_data = (data) => {
  //   console.log(data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  //   // this.forceUpdate();
  //   setFullBc(true);
  // };
  // const bcHandler = () => {
  //   setFullBc(false);
  // };
  // console.log(pathName);
  return (
    <section>
      <div>
        <div className="about">
          <div className="breadcrumbs">
            <div class="container">
              <ol>
                <li>
                  <Link to="/#hero">Home</Link>
                </li>
                {/* <li>
                  <Link to="/blogs">Blog</Link>
                </li> */}
                <li>Blog</li>
              </ol>
              {/* <h2>Blog</h2> */}
            </div>
          </div>
          <div class="container">
            <div class="section-title pt-5 pb-12" data-aos="fade-up">
              <h2 style={{ color: "#010483", fontSize: 36, fontWeight: 700 }}>
                Blog
              </h2>
            </div>
            <div className="row">
              {/* <div
                class="sk-ww-medium-publication-feed"
                data-embed-id="74819"
              ></div>
              <script
                src="https://widgets.sociablekit.com/medium-publication-feed/widget.js"
                async
                defer
              ></script> */}
              {/* <iframe
                src="https://widgets.sociablekit.com/medium-publication-feed/iframe/74819"
                frameborder="0"
                width="100%"
                height="1000"
              ></iframe> */}
              {/* <BlogTemplate /> */}
              {/* <FullBlog /> */}
              <Routes>
                <Route index element={<BlogTemplate />} />
                {/* <Route
                  path="/blog-single"
                  element={<FullBlog func={pull_data} />}
                /> */}
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Blogs;
