import React, { useState } from "react";
import Button from "@mui/material/Button";
import { NavLink, Link } from "react-router-dom";
function NavBar() {
  const [blogIsActive, setBlogActive] = useState(false);
  const [isHome, setIsHome] = useState(true);

  const [isMobile, setIsMobile] = useState(false);
  const [tranparentHeader, setTransparentHeader] = useState(true);

  const [isDemo, setIsDemo] = useState(false);
  const [isFeatures, setIsFeatures] = useState(false);
  const [isProducts, setIsProducts] = useState(false);
  const [isTeam, setIsTeam] = useState(false);
  const [isContact, setIsContact] = useState(false);
  // const blogHandler = () => {

  // };
  const pathName = window.location.pathname;
  const highlightNavBar = () => {
    if (pathName == "/blogs") {
      setBlogActive(true);
      setIsHome(false);
    } else {
      setBlogActive(false);
    }
    if (window.scrollY <= 3036 && !blogIsActive) {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
    if (window.scrollY > 3036 && window.scrollY < 4452) {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
    if (window.scrollY >= 4452 && window.scrollY < 7716) {
      setIsFeatures(true);
    } else {
      setIsFeatures(false);
    }
    if (window.scrollY >= 7716 && window.scrollY < 10698) {
      setIsProducts(true);
    } else {
      setIsProducts(false);
    }
    if (window.scrollY >= 10698 && window.scrollY < 12000) {
      setIsTeam(true);
    } else {
      setIsTeam(false);
    }
    if (window.scrollY >= 12000) {
      setIsContact(true);
    } else {
      setIsContact(false);
    }
  };

  const changeHeaderTransparency = () => {
    if (window.scrollY >= 80) {
      setTransparentHeader(false);
    } else {
      setTransparentHeader(true);
    }
  };
  const navBarSet = () => {
    if (isMobile) {
      setIsMobile(!isMobile);
    } else {
      // setTransparentHeader(true);
    }
  };

  window.addEventListener("scroll", highlightNavBar);
  window.addEventListener("scroll", changeHeaderTransparency);
  return (
    <header
      id="header"
      className={
        tranparentHeader
          ? "fixed-top d-flex align-items-center header-transparent "
          : "fixed-top d-flex align-items-center header-scrolled"
      }
    >
      <div className="container d-flex align-items-center justify-content-between">
        <div className="logo">
          {/* <h1>
            <a href="index.html">
              <span>Bootslander</span>
            </a>
          </h1> */}
          <a>
            <Link to="/">
              <img src="assets/img/logo.png" alt="" className="img-fluid" />
            </Link>
          </a>
        </div>

        <nav
          id="navbar"
          className={isMobile ? "navbar navbar-mobile" : "navbar"}
          // {isMobile ? "navbar" : "navbar-mobile"}
          onClick={navBarSet}
        >
          <ul>
            <li>
              <a
                className={`nav-link scrollto ${isHome ? "active" : ""}`}
                href="/#hero"
                onClick={() => {
                  setBlogActive(false);
                }}
              >
                Home
              </a>
            </li>
            <li>
              <a
                className={`nav-link scrollto ${isDemo ? "active" : ""}`}
                href="/#demo"
              >
                Demo
              </a>
            </li>
            <li>
              <a
                className={`nav-link scrollto ${isFeatures ? "active" : ""}`}
                href="/#appFeatures"
              >
                Features
              </a>
            </li>
            <li>
              <a
                className={`nav-link scrollto ${isProducts ? "active" : ""}`}
                href="/#products"
              >
                Products
              </a>
            </li>
            <li>
              <a
                className={`nav-link scrollto ${isTeam ? "active" : ""}`}
                href="/#team"
              >
                Team
              </a>
            </li>
            <li>
              <a
                className={`nav-link scrollto ${isContact ? "active" : ""}`}
                href="/#contact"
              >
                Contact
              </a>
            </li>
            <li>
              <NavLink to="/blogs">
                <Button
                  variant="outlined"
                  style={{
                    paddingLeft: "30",
                    color: "#1ACC8D",
                    textTransform: "capitalize",
                    fontSize: "15px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                  }}
                  // onClick={blogHandler}
                >
                  Blog
                </Button>
              </NavLink>
            </li>
            {/* <li>
              <a to="/blogs">
                <Button
                  variant="outlined"
                  style={{
                    paddingLeft: "30",
                    color: "#1ACC8D",
                    textTransform: "capitalize",
                    fontSize: "15",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                  }}
                >
                  Blog
                </Button>
              </a>
            </li> */}
          </ul>
          <i
            className={
              isMobile
                ? "bi mobile-nav-toggle bi-x "
                : "bi mobile-nav-toggle bi-list "
            }
            onClick={() => setIsMobile(!isMobile)}
          ></i>
        </nav>
      </div>
    </header>
  );
}
export default NavBar;
