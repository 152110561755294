import NavBar from "./components/navbar";
import BackToTop from "./components/backToTop";
import Loader from "./components/loader";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BlogTemplate from "./components/blogSection/singleBlog";
import FullBlog from "./components/blogSection/fullBlog";
// import Lottie from "react-lottie";
// import animationData from "./components/loader.json";
import React, { Suspense, useState, useEffect } from "react";
import Blogs from "./components/blogs";
// import AllPage from "./components/allPages";
const AllPages = React.lazy(() => import("./components/allPages"));

function App() {
  // window.addEventListener("scroll", (event) => {
  //   console.log(window.scrollY);
  //   console.log(window.scrollX);
  // });
  const [spinner, setSpinner] = useState(true);
  useEffect(() => {
    setTimeout(() => setSpinner(false), 2000);
  }, []);
  return spinner ? (
    <div>
      <Loader />
    </div>
  ) : (
    <Suspense fallback={<Loader />}>
      <NavBar />
      <Routes>
        <Route path="/" element={<AllPages />} />
        <Route path="/blogs/*" element={<Blogs />}>
          <Route path="blog-single" element={<FullBlog />} />
        </Route>
        {/* <Route path="/blogs/blog-single" element={<FullBlog />} /> */}
      </Routes>
      {/* <AllPages /> */}
      {/* <Blogs /> */}
      <BackToTop />
    </Suspense>
  );
}

export default App;
