import React, { useState } from "react";
// import FullBlog from "./fullBlog";
import { Link, Route } from "react-router-dom";

function BlogTemplate() {
  // const [isFullBlog, setFullBlog] = useState(false);
  // const fullBlogHandler = () => {
  //   setFullBlog(true);
  // };
  return (
    <section id="blog" class="blog">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-8 entries">
            <article class="entry">
              <div class="entry-img">
                <img
                  src="https://www.dropbox.com/s/5e4m707e6bqumuv/Laptop%20Guide.jpg?raw=1"
                  alt=""
                  class="img-fluid"
                />
              </div>

              <h2 class="entry-title">
                <a
                  href="https://www.dropbox.com/s/wda5x01vk4pbhie/decent%20book%20of%20recommendations%20to%20buying%20a%20laptop%20for%20an%20engineering%20college%20t.pdf?raw=1"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Decent book of recommendations to buying a Laptop for an
                  Engineering College
                </a>
              </h2>

              <div class="entry-meta">
                <ul>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-person"></i> <a>Param Mahabare</a>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-clock"></i>{" "}
                    <a>
                      <time datetime="2020-01-01">March 17, 2022</time>
                    </a>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-book"></i>
                    <a>3 min read</a>
                  </li>
                </ul>
              </div>

              <div class="entry-content">
                <p>
                  Guide and its contents on an “as is” basis and make no
                  representations or warranties of any kind with respect to this
                  guide or its contents. The publisher and the author disclaim
                  all such representations and warranties, including but not
                  limited to warranties of the individual devices mentioned.
                </p>
                <div class="read-more">
                  {/* <a onClick={fullBlogHandler}>Read More</a> */}
                  <a
                    href="https://www.dropbox.com/s/wda5x01vk4pbhie/decent%20book%20of%20recommendations%20to%20buying%20a%20laptop%20for%20an%20engineering%20college%20t.pdf?raw=1"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-8 entries">
            <article class="entry">
              <div class="entry-img">
                <img
                  src="https://miro.medium.com/max/875/0*1NA1lnVrlv85c128.png"
                  alt=""
                  class="img-fluid"
                />
              </div>

              <h2 class="entry-title">
                <a
                  href="https://medium.com/@coolage/scholarships-awards-nit-j-6c39806de30"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Scholarships & Awards @ NIT J
                </a>
              </h2>

              <div class="entry-meta">
                <ul>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-person"></i> <a>Coolage NIT-J</a>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-clock"></i>{" "}
                    <a>
                      <time datetime="2020-01-01">Feb 21, 2022</time>
                    </a>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-book"></i>
                    <a>5 min read</a>
                  </li>
                </ul>
              </div>

              <div class="entry-content">
                <p>
                  In the twenty-first century, affording education has become a
                  very challenging undertaking. Scholarships, Awards can finance
                  a portion or all of a student’s tuition and fees, ensuring
                  that hardworking and underserved students have an opportunity
                  to pursue higher education.
                </p>
                <div class="read-more">
                  {/* <a onClick={fullBlogHandler}>Read More</a> */}
                  <a
                    href="https://medium.com/@coolage/scholarships-awards-nit-j-6c39806de30"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>

      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-8 entries">
            <article class="entry">
              <div class="entry-img">
                <img
                  src="https://miro.medium.com/max/875/1*kV3IRBbXNh1CljDgxxXDgQ.jpeg"
                  alt=""
                  class="img-fluid"
                />
              </div>

              <h2 class="entry-title">
                <a
                  href="https://medium.com/@coolage/placement-diaries-brahmleen-kaur-66ce5cef383b"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Placement Diaries: Brahmleen Kaur
                </a>
              </h2>

              <div class="entry-meta">
                <ul>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-person"></i> <a>Coolage NIT-J</a>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-clock"></i>{" "}
                    <a>
                      <time datetime="2020-01-01">Feb 17, 2022</time>
                    </a>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-book"></i>
                    <a>8 min read</a>
                  </li>
                </ul>
              </div>

              <div class="entry-content">
                <p>
                  It’s the Indian Dream, to one day get to work in a tech giant
                  such as Google, Microsoft, or Amazon. In this edition, we
                  spoke to someone who got work opportunities in all of them!
                  That is in addition to an internship at ISRO, and campus
                  placement in Lowes, India. She is by definition the prodigy of
                  NIT-J. She is Brahmleen Kaur.
                </p>
                <div class="read-more">
                  {/* <a onClick={fullBlogHandler}>Read More</a> */}
                  <a
                    href="https://medium.com/@coolage/placement-diaries-brahmleen-kaur-66ce5cef383b"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-8 entries">
            <article class="entry">
              <div class="entry-img">
                <img
                  src="https://miro.medium.com/max/875/1*4yWng6e4CW2tt0kIPmjDHA.jpeg"
                  alt=""
                  class="img-fluid"
                />
              </div>

              <h2 class="entry-title">
                <a
                  href="https://medium.com/@coolage/placement-diaries-bhanu-pratap-9e5847338c8"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Placement Diaries: Bhanu Pratap
                </a>
              </h2>

              <div class="entry-meta">
                <ul>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-person"></i> <a>Coolage NIT-J</a>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-clock"></i>{" "}
                    <a>
                      <time datetime="2020-01-01">Feb 13, 2022</time>
                    </a>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-book"></i>
                    <a>9 min read</a>
                  </li>
                </ul>
              </div>

              <div class="entry-content">
                <p>
                  Welcome readers, to the first edition of Placement Diaries.
                  Few people have had the privilege of being part of NIT-J’s
                  folklore, and in this edition, we spoke to one such man. Bhanu
                  Pratap Singh Chauhan — his CV is as magnificent as his name.
                  Recently placed in a Super Dream company — Samsung, Bhanu is
                  also famous for being the guy who ditched Chemical for ICE in
                  his first year. With his many internships on his resume and
                  stars on his CodeChef profile, he has a lot to share about his
                  experiences for juniors.
                </p>
                <div class="read-more">
                  {/* <a onClick={fullBlogHandler}>Read More</a> */}
                  <a
                    href="https://medium.com/@coolage/placement-diaries-bhanu-pratap-9e5847338c8"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-8 entries">
            <article class="entry">
              <div class="entry-img">
                <img
                  src="https://miro.medium.com/max/1400/1*8HQJtbkMnGOqO6fPKASNgw.png"
                  alt=""
                  class="img-fluid"
                />
              </div>

              <h2 class="entry-title">
                <a
                  href="https://bhaskar-raj.medium.com/birds-of-nit-jalandhar-6a4d2550ce81"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Birds of NIT Jalandhar
                </a>
              </h2>

              <div class="entry-meta">
                <ul>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-person"></i> <a>Bhaskar Raj</a>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-clock"></i>{" "}
                    <a>
                      <time datetime="2020-01-01">Feb 10, 2022</time>
                    </a>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-book"></i>
                    <a>5 min read</a>
                  </li>
                </ul>
              </div>

              <div class="entry-content">
                <p>
                  They have wings, they are free, they can fly, they have that
                  mobility that you envy and yet they are here unlike you.
                  Coolage, urges the readers to delve into a world dying with
                  the fast pace of life — the world of bird-watching. This
                  article aims to list out few of the most exquisite birds found
                  in NIT Jalandhar. With the help of talented photographers of
                  our campus, we have compiled this photographic journal of the
                  sky creatures who are commonly spotted on our campus.
                </p>
                <div class="read-more">
                  {/* <a onClick={fullBlogHandler}>Read More</a> */}
                  <a
                    href="https://bhaskar-raj.medium.com/birds-of-nit-jalandhar-6a4d2550ce81"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BlogTemplate;
